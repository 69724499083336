import Vue from "vue";
import Vuex from "vuex";
import API from "app/axios";
// var _ = require("lodash/core")
import packageInfo from "../package.json";
import stopWords from "./data/stopwords.json";
import dayjs from "dayjs";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    htmlBuild: "",
    navHistory: [],

    // page layout containers
    v6Sections: [],
    v6Topic: [],
    v6CleanLog: {},

    tabs: {},
    __timers: {},
    packageVersion: packageInfo.version,
    stopWords: stopWords,
    hasPlugin: false, // User has the Chrome plugin installed
    hasChrome: false, // Running _within_ the chrome plugin
    hasApp: false, // User is _within_ the app
    hasMini: false, // Router says this page is *always* mini
    hasMiniView: false, // URL says this *whole site* is *always* mini (iframe, pwa etc?)
    hasIOSApp: false,
    hasAppPlatform: "", // The App Platform -- for tracking!
    hasAppVersion: 0, // The App Version -- for migrations
    minorAppVersion: 0, // The App Version -- for migrations

    // default copied from nourished.news Nov 10, 2021
    osConfig:
      window._os_config ||
      JSON.parse(
        `{"blogs":{"subscription-model":false},"account":{"notif":{"email":{"frequency":[{"id":1,"name":"Daily","code":"daily"},{"id":2,"name":"Every few days","code":"every-few-days"},{"id":3,"name":"Weekdays","code":"weekdays"},{"id":4,"name":"Saturday","code":"saturday"}],"timings":[{"id":1,"name":"Morning","code":"morning","note":"~8am"},{"id":2,"name":"Lunchtime","code":"lunchtime","note":"~12:30pm"},{"id":3,"name":"Evening","code":"evening","note":"~6pm"}],"defaults":{"freq":2,"time":1}},"mobile":{"frequency":[{"id":1,"name":"Daily","code":"daily"},{"id":2,"name":"Every few days","code":"every-few-days"},{"id":3,"name":"Weekdays","code":"weekdays"},{"id":4,"name":"Saturday","code":"saturday"}],"timings":[{"id":1,"name":"Morning","code":"morning","note":"~8am"},{"id":2,"name":"Lunchtime","code":"lunchtime","note":"~12:30pm"},{"id":3,"name":"Evening","code":"evening","note":"~6pm"}],"defaults":{"freq":2,"time":1}}}}}`
      ),

    threads_overlay: "",
    articleURL: {},
    articleTimes: {},
    lib_full_topics: {},
    lib_section_headers: {},
    lib_pagination: {},
    lib_temp_topics: [],
    lib_topic_details: {},
    lib_headline_topics: {},
    lib_headline_topics_data: {},
    lib_micro_challenges: {},
    lib_completed_challenges: {},
    lib_d3: {}, // d3 Assets Library
    lib_history_topic_coverage: {},
    lib_history_publisher_coverage: {},
    lib_publishers: {},
    lib_status: [],
    lib_read_next: {},
    lib_read_next_data: {},
    lib_home: {},
    lib_chart_topic: {},
    lib_trending_topics: {},
    lib_following: {},
    last_read: "",
    account: window._os_user || {
      details: {},
      preferences: {},
      subscription: {},
      onboarding: {},
    },
    account_blank: {
      details: {},
      preferences: {},
      subscription: {},
      onboarding: {},
    },
    showAccountModal: false,
    chromeExtension: false,
    chromeExtensionVersion: false,

    debugFlags: {
      showAllTopics: true,
    },

    // v3.2 homepage hack
    v3Home_TEMP: {},

    // Experimental v3 stack
    __v3: {
      pos: "home",
      stack: [],
      prepared: {},

      _demo: {
        home: [
          { type: "headline", text: "Hello World" },
          { type: "basic", text: "Box", next: "foo" },
          { type: "basic", text: "Box", next: "foo" },
          { type: "basic", text: "Box", next: "foo" },
          { type: "basic", text: "Box", next: "foo" },
          { type: "basic", text: "Box", next: "foo" },
          { type: "basic", text: "Box", next: "foo" },
          { type: "basic", text: "Box", next: "foo" },
          { type: "basic", text: "Box", next: "foo" },
          { type: "pill", text: "Topic Name" },
        ],
        foo: [
          { type: "basic", text: "Foo" },
          { type: "basic", text: "Foo" },
          { type: "pill", text: "Bar" },
        ],
      },
    },

    __readstate: {},

    // New extensible library..
    __library: {
      _active: {},

      sections: {
        url: "/sections/get/:slug/",
        multi: "slug",
        propagate: {
          stories: { to: "stories", by: "slug" },
          subsections: { to: "sections", by: "code" },
        },
      },

      stories: {
        url: "/stories/get/:slug/",
        multi: "slug",
        propagate: {
          primary_article: { to: "articles", by: "slug" },
          alternative_articles: { to: "articles", by: "slug" },
          links: { to: "articles", by: "slug" },
        },
      },

      answers: {
        url: "/answers/get/:slug/",
        multi: "slug",
        propagate: {
          stories: { to: "story_stems_v3", by: "slug" },
        },
      },

      answers_all: { url: "/answers/latest" },

      articles: { url: "/articles/get/:slug/", multi: "slug" },
      links: { url: "/articles/?link=:link", multi: "link" },

      topics: {
        url: "/topics/get/:slug/",
        multi: "slug",
        propagate: {
          // stories:              { to: 'stories', by: 'slug' },
          stories: { to: "story_stems_v3", by: "slug" },
          topics: { to: "topics_v3", by: "topic_code" },
        },
      },

      topic_articles: {
        url: "/topics/articles/:slug/",
        multi: "slug",
        propagate: {
          articles: { to: "articles", by: "slug" },
        },
      },

      history: { url: "/account/history/", action: "storeLibraryHistory" },

      report: { url: "/account/report/" },

      editions: { url: "/sections/editions" },

      // threads
      threads_mine: {
        url: "/thread/list",
        propagate: {
          threads: { to: "threads", by: "slug" },
        },
      },
      threads_xname: {
        url: "/thread/get/:xname",
        multi: "xname",
        propagate: {
          threads: { to: "threads", by: "slug" },
        },
      },
      threads: { url: "/thread/get/:xname/:slug", multi: "slug" }, // WARNING: possible slug-clash in store.

      // social graph
      followers: { url: "/thread/followers/:xname", multi: "xname" },
      following: { url: "/thread/following/:xname", multi: "xname" },

      // profile
      threads_feed: { url: "/thread/feed" },
      threads_demo_feed: { url: "/thread/demofeed" },

      // cms & blog
      cms_pages_map: { url: "/content/pages/map/" },
      cms_pages_content: {
        url: "/content/pages/content/:slug/",
        multi: "slug",
      },
      cms_blog_map: { url: "/content/blog/map/" },
      cms_blog_content: { url: "/content/blog/content/:slug/", multi: "slug" },

      // tools, debugging etc..
      tools_cluster_stories: { url: "/data/story-engine/" },

      next: { url: "/home/next/:locale/?cluster=:cluster" },

      // v3 - scratchpad
      edition_v3: {
        url: "/home/edition/:locale?cluster=:cluster",
        propagate: {
          sections: { to: "sections_v3", by: "code" },
          stories: { to: "story_stems_v3", by: "slug" },
          topics: { to: "topics_v3", by: "topic_code" },
        },
      },
      sections_v3: {},
      story_stems_v3: {},
      topics_v3: {},

      story_v3: { url: "/stories/get/:slug/?v3=on", multi: "slug" },
      story_node_v3: {
        url: "/stories/get/:slug/?v3=on&node=:node",
        multi: "node",
      },

      stack_shelves: { url: "/home/shelves" },
      stack: { url: "/home/stacks/:slug/", multi: "slug" },

      edition_v6: {
        url: "/home/edition/:locale?cluster=:cluster",
        propagate: {
          sections: { to: "sections_v3", by: "code" },
          stories: { to: "story_stems_v3", by: "slug" },
          topics: { to: "topics_v3", by: "topic_code" },
        },
      },

      edition_pro: {
        url: "/home/pro/:slug?cluster=:cluster&q=:q",
        propagate: {
          sections: { to: "sections_v3", by: "code" },
          stories: { to: "story_stems_v3", by: "slug" },
          topics: { to: "topics_v3", by: "topic_code" },
        },
      },

      // into which day do we correlate the data?
      dial: {
        url: "/account/dial?offset=" + new Date().getTimezoneOffset(),
        auth: true,
      },
    },

    // Anything below this line is about to be deleted!
    lib_topics: {},
    lib_stories: {},
    lib_articles: {},
    lib_sections: {},
    lib_read_statuses: {},
    lib_history: {},
    lib_report: {},
  },

  /**
   * New Storage Framework..
   * __________________________________________________
   */

  actions: {
    fetch({ dispatch, commit, getters }, args) {
      // console.log(`\x1b[35mStore -- Starting fetch: ${args.endpoint}`, args);

      // Setup store basics first time around
      return dispatch("instantiateLibaryItem", args.endpoint).then((store) => {
        // console.log(`\x1b[35mStore -- instantiateLibaryItem: ${args.endpoint}`);

        // Now fetch the item, if we can..
        return dispatch("getLibraryItem", {
          ...{ store: store },
          ...args,
        }).then((extant) => {
          // console.log(`\x1b[35mStore -- getLibraryItem: ${args.endpoint}`);

          if (store.auth && !this.getters.isLoggedIn) {
            // console.warn(`\x1b[35mStore -- Not authed for auth-only endpoint '${args.endpoint}'.  Ignoring`);
            return {};
          }

          // If we've got it - great!
          if (extant && !args?.params?.force) {
            // let iTime = Math.round(new Date().getTime() / 1000);
            // let iRemain = extant.expires - iTime;
            // console.log(`\x1b[35mStore -- Extant: ${args.endpoint}, Expires: +${iRemain}`, args);
            return extant;
          }

          // No? Fetch..
          let url = store.url.replace(
            /:(\w+)/g,
            (n, s) => args?.params?.[s] || ""
          );
          let active = getters.getLibraryActive(url);
          let time = new Date().getTime() / 1000;

          // this is about throttling/debouncing 'active' (ie 'running') requests..
          // for data expiry see getLibraryItem();
          if (time - active < 60) {
            // console.log(`\x1b[35mStore -- Freshness: ${args.endpoint}`, true);
            return true;
          }

          commit("logLibraryActivity", { url: url, active: true });

          // console.warn(`\x1b[35mStore -- Running: ${args.endpoint}`, store, url);
          return API.get(url)
            .then((response) => {
              dispatch("storeLibraryItem", {
                ...{ store: store, data: response.data, full: true },
                ...args,
              }).then(() => {
                commit("logLibraryActivity", { url: url, active: false });
              });

              // console.log(`\x1b[35mStore -- Fetched: ${args.endpoint}`, response.data);
              return response.data;
            })
            .catch((error) => {
              throw `Library: API Error ${error}`;
            });
        });
      });
    },

    storeLibraryItem(
      { commit, dispatch },
      { store, endpoint, params, data, full }
    ) {
      // Allow propagated storage of sub-objects
      // Basically break down big objects: list of widgets > widgets

      if (store.propagate) {
        Object.keys(store.propagate).forEach((k) => {
          let o = store.propagate[k];

          // Sense check..
          if (!o.to || !o.by) {
            throw `Library: Misconfigured propagation in :${endpoint} (:${k})`;
          }

          // Tidy singletons to consistent arrays..
          let input = data[k];
          if (!input) {
            return;
          }
          if (!Array.isArray(input)) {
            input = [input];
          }

          dispatch("instantiateLibaryItem", o.to).then((prop_store) => {
            // Iterate & store..
            input.forEach((row) => {
              // eslint-disable-line no-unused-vars

              dispatch("storeLibraryItem", {
                store: prop_store,
                data: row,
                params: {
                  [prop_store.multi]: row[o.by],
                },
                endpoint: prop_store.endpoint,
                full: false,
              });
            });
          });
        });
      }

      if (store.action) {
        dispatch(store.action, arguments[1]);
      }

      let item_key = params && params[store.multi] ? params[store.multi] : "_";
      commit("storeLibraryItem", {
        endpoint: endpoint,
        item_key: item_key,
        full: full, // Full data, pulled from an specific endpoint..
        data: data,
      });

      // console.log(`Commit: ${endpoint}:${item_key} / full: ${full} / data: `, data);
    },

    storeLibraryHistory({ commit, dispatch }, { data }) {
      // Prepare JIT the stories & articles
      dispatch("instantiateLibaryItem", "stories");
      dispatch("instantiateLibaryItem", "articles");

      // Get our endpoint data & iterate through the list
      let history = Array.isArray(data.history) ? data.history : [];

      history.forEach((h) => {
        // Store the story (if there is one..
        if (h.story) {
          commit("storeLibraryItem", {
            endpoint: "stories",
            item_key: h.story.slug,
            data: h.story,
          });
        }
        // Store the article
        commit("storeLibraryItem", {
          endpoint: "articles",
          item_key: h.link.slug,
          data: h.link,
        });
      });
    },

    // eslint-disable-next-line
    getLibraryItem({ dispatch }, { store, endpoint, params }) {
      // eslint-disable-line no-unused-vars

      // console.log(`\x1b[35mStore -- ${endpoint}`);
      let item_key = store.multi;
      let current = false;

      // If we're multi - look up by key
      if (item_key) {
        if (!params[item_key]) {
          throw `Library: Enpoint :${endpoint} requires key :${item_key}`;
        }
        current = store.shelf[params[item_key]];
      } else {
        current = store.shelf["_"];
      }

      // Don't have anything - fine..
      if (!current) {
        return false;
      }

      // Don't have anything - fine..
      if (!current.full) {
        return false;
      }

      // Are we 'forcing' a refresh of even "expired" data?
      if (params?._force) {
        // could maybe make this like... 30s?
        return false;
      }

      let current_time = Math.round(new Date().getTime() / 1000);
      if (current.expires < current_time) {
        return false;
      } else {
        // let ttl = current.expires - current_time;
        // console.log(`\x1b[35mStore -- Age: `, {ttl: ttl, expires: current.expires, current: current_time, force: params?._force});
      }

      // All good! Return the book, from the shelf in the library (thanks brew!)
      return { ...current.book, expires: current.expires };
    },

    /**
     * Fetch the endpoint, checking it's defined in the library and seting up foundation properties
     */
    instantiateLibaryItem({ state }, endpoint) {
      // Look up endpoint defaults or balk
      if (!state.__library[endpoint]) {
        throw `Enpoint :${endpoint} not configured.`;
      }
      // Instantiate.. (setup some basic properties (rather than specifying everything in state))
      // This seems quick, easier to migrate in new features and keeps the definition tidy..
      if (!state.__library[endpoint].shelf) {
        // Need to add this to be reactive.. (must use Vue.set)
        Vue.set(state.__library[endpoint], "endpoint", endpoint);
        Vue.set(state.__library[endpoint], "shelf", {});
      }
      // return
      return state.__library[endpoint];
    },

    resetProEdition({ commit }) {
      // overwrite the storage
      commit("storeLibraryItem", {
        endpoint: "edition_pro",
        item_key: "_",
        full: true,
        data: {},
      });
    },
  },

  /**
   * Legacy ...
   * __________________________________________________
   */

  getters: {
    getHtmlBuild(state) {
      return state.htmlBuild;
    },

    getV6Sections(state) {
      return state.v6Sections || [];
    },

    getV6Topic(state) {
      return state.v6Topic || [];
    },

    getV6CleanLog(state) {
      return state.v6CleanLog || {};
    },

    getV6CleanLogItem: (state) => (key) => {
      return state.v6CleanLog[key] || false;
    },

    getHighlightsPro(state) {
      return state.highlights_pro || [];
    },

    getNavHistory(state) {
      return state.navHistory || [];
    },

    getTab: (state) => (code) => {
      return state.tabs[code];
    },

    getV3Pos: (state) => {
      return state.__v3.pos;
    },

    getV3Tiles: (state) => (path) => {
      let sPath = path || "/";
      return state.__v3.prepared[sPath];
    },

    getV3HomeTEMP: (state) => {
      return state.v3Home_TEMP;
    },

    /**
     *  Getter wrappers
     *  _______________________
     */
    getLibraryItem: (state) => (endpoint, slug) => {
      if (state.__library[endpoint] && state.__library[endpoint].shelf) {
        slug = slug || "_";
        if (state.__library[endpoint].shelf[slug]) {
          return state.__library[endpoint].shelf[slug].book;
        }
      }
      // console.log(`\x1b[31mStore -- No endpoint: ${endpoint}`);
      return false;
    },

    getLibraryActive: (state) => (url) => {
      return state.__library._active[url] || 0;
    },

    getReport: (state) => {
      return state.lib_report;
    },

    getAllTopicSlugs: (state) => {
      // hack (pending topic updates) for /deck readstate
      let aAllSlugs = [];

      Object.entries(state.__library["topics"]?.shelf || {}).forEach(
        (aItem) => {
          let oItem = aItem[1];
          let oTopic = oItem.book;
          let aSlugs = oTopic.stories.map((oStory) => oStory.slug);
          // stack
          aAllSlugs = aAllSlugs.concat(aSlugs);
          // console.log(`[getAllTopicSlugs] :: `, aSlugs);
        }
      );
      return aAllSlugs;
    },

    getAllTopicArticleSlugs: (state) => {
      // hack (pending topic updates) for /deck readstate
      let aAllSlugs = [];

      Object.entries(state.__library["topic_articles"]?.shelf || {}).forEach(
        (aItem) => {
          let oItem = aItem[1];
          let oTopic = oItem.book;
          let aSlugs = oTopic.articles.map((oStory) => oStory.slug);
          // stack
          aAllSlugs = aAllSlugs.concat(aSlugs);
          console.log(`[getAllTopicArticleSlugs] :: `, aSlugs);
        }
      );
      return aAllSlugs;
    },

    /**
     *  Updated getters..
     *  _______________________
     */
    getStackShelves: (_, getters) => {
      // eslint-disable-line no-unused-vars
      return getters.getLibraryItem("stack_shelves");
    },

    getStack: (_, getters) => (slug) => {
      return getters.getLibraryItem("stack", slug);
    },

    getTopSection: (_, getters) => (slug) => {
      // console.warn("Deprecated. Use getSection");
      return getters.getSection(slug);
    },

    getSection: (_, getters) => (slug) => {
      return getters.getLibraryItem("sections", slug);
    },

    getAnswer: (_, getters) => (slug) => {
      return getters.getLibraryItem("answers", slug);
    },

    getAnswersAll: (_, getters) => () => {
      return getters.getLibraryItem("answers_all");
    },

    getStory: (_, getters) => (slug) => {
      return getters.getLibraryItem("stories", slug);
    },

    getArticle: (_, getters) => (slug) => {
      return getters.getLibraryItem("articles", slug);
    },

    getLink: (_, getters) => (link) => {
      return getters.getLibraryItem("links", link);
    },

    getTopic: (_, getters) => (slug) => {
      return getters.getLibraryItem("topics", slug);
    },

    getTopicArticles: (_, getters) => (slug) => {
      return getters.getLibraryItem("topic_articles", slug);
    },

    getHistory: (_, getters) => {
      // eslint-disable-line no-unused-vars
      return getters.getLibraryItem("history").history;
    },

    // getReport: (_, getters) => {
    // 	return getters.getLibraryItem('report');
    // },

    getEditions: (_, getters) => {
      // eslint-disable-line no-unused-vars
      return getters.getLibraryItem("editions");
    },

    getNext: (_, getters) => () => {
      return getters.getLibraryItem("next");
    },

    // cms
    getCMSMap: (_, getters) => (area) => {
      // eslint-disable-line no-unused-vars
      return getters.getLibraryItem(`cms_${area}_map`);
    },
    getCMSContent: (_, getters) => (area, slug) => {
      // eslint-disable-line no-unused-vars
      return getters.getLibraryItem(`cms_${area}_content`, slug);
    },

    // tools & debugging...

    getToolsClusterStories: (state, getters) => {
      // eslint-disable-line no-unused-vars
      return getters.getLibraryItem("tools_cluster_stories");
    },

    /**
     * ___________________________________
     */

    getEditionStoryStem: (_, getters) => (slug) => {
      return getters.getLibraryItem("story_stems_v3", slug);
    },

    getEditionTopic: (_, getters) => (slug) => {
      // console.log("getEditionTopic ", slug, getters.getLibraryItem('topics_v3', slug));
      return getters.getLibraryItem("topics_v3", slug);
    },

    getStoryV3: (_, getters) => (slug) => {
      return getters.getLibraryItem("story_v3", slug);
    },
    getStoryNodeV3: (_, getters) => (node) => {
      // issue (should be slug+node combo?)
      // console.log("Getting: getStoryNodeV3:", node,  getters.getLibraryItem('story_node_v3', node));
      return getters.getLibraryItem("story_node_v3", node);
    },

    /**
     * ___________________________________
     */

    getThread: (_, getters) => (slug) => {
      // console.log(`Getting: Threads: ${slug}`);
      return getters.getLibraryItem("threads", slug);
    },

    hasThreads: (_, getters) => {
      return getters.getAccountFlag("threads_active");
    },

    isInfluencer: (_, getters) => {
      return getters.getAccountFlag("influencer_active");
    },
    /**
     * ___________________________________
     */

    readState: (state) => (slug) => {
      // console.log(`UserEngine --- GET: (${slug})`, state.__readstate[slug]);
      return state.__readstate[slug] || {};
    },
    // for mutation observation just..
    readStateAll: (state) => {
      return state.__readstate;
    },

    /**
     * ___________________________________
     */

    timer: (state) => (slug) => {
      return state.__timers[slug] || null;
    },
    timers: (state) => {
      return state.__timers || {};
    },
    timers_runnning: (state) => {
      let running = {};
      for (const [key, value] of Object.entries(state.__timers)) {
        if (value.running) {
          running[key] = value;
        }
      }
      return running || {};
    },
    timers_runnning_keys: (_, getters) => {
      return Object.keys(getters.timers_runnning);
    },
    timer_keys: (state) => {
      return Object.keys(state.__timers) || [];
    },

    /**
     * ___________________________________
     */

    isStopWord: (state) => (slug) => {
      // console.log("STOP--ALL: ", slug.trim().replace(/\W*/,'').toLowerCase(), state.stopWords);
      return (
        state.stopWords[slug.trim().replace(/\W*/, "").toLowerCase()] || false
      );
    },

    appVersion: (state) => {
      return state.packageVersion;
    },
    hasPlugin(state) {
      return state.hasPlugin;
    },
    articleURL(state) {
      return state.articleURL;
    },
    showAccountModal(state) {
      return state.showAccountModal;
    },
    hasMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ); // || state.hasApp;
    },
    hasMini(state) {
      return state.hasMini || state.hasMiniView || state.hasChrome;
    },
    hasApp(state) {
      return state.hasApp;
    },
    hasIOSApp(state) {
      return state.hasIOSApp;
    },
    hasAppPlatform(state) {
      return state.hasAppPlatform;
    },
    hasAppVersion(state) {
      return state.hasAppVersion;
    },
    minorAppVersion(state) {
      return state.minorAppVersion;
    },
    hasChrome(state) {
      return state.hasChrome;
    },
    hasNoChromeExtension(state) {
      return !state.chromeExtension;
    },
    hasChromeExtension(state) {
      return state.chromeExtension;
    },
    chromeExtensionVersion(state) {
      return state.chromeExtensionVersion;
    },
    isChromeBrowser() {
      const chrome = window.chrome;
      const winNav = window.navigator;
      const vendorName = winNav.vendor;
      const opera = typeof window.opr !== "undefined";
      const edge = winNav.userAgent.indexOf("Edge") > -1;
      const chromeiOS = winNav.userAgent.match("CriOS");

      if (
        chrome !== null &&
        typeof chrome !== "undefined" &&
        vendorName === "Google Inc." &&
        opera === false &&
        edge === false &&
        !chromeiOS
      ) {
        return true;
      }

      return false;
    },

    linkMode: (_, getters) => (hint) => {
      // pick a default mode, obey the override
      let mode = hint || "guess";

      // guess, most of the time
      if (mode == "guess") {
        if (getters.hasApp) {
          mode = "callback";
        } else if (getters.hasMobile) {
          mode = "navigate";
        } else {
          mode = "window";
        }
      }

      return mode;
    },

    getAccount(state) {
      return state.account;
    },

    getSettings(state) {
      return state.account?.settings || {};
    },

    isAccountReady(state) {
      return state.account?.preferences?.country ? true : false;
    },

    getExcludedPublishers(state) {
      if (state.account.preferences.excluded_publishers) {
        return state.account.preferences.excluded_publishers;
      } else {
        return JSON.parse(localStorage.getItem("excluded_publishers")) || [];
      }
    },

    getToken(state) {
      // nb - something weird (possible just on localhost) where token == (string)"undefined"... not handled well.

      if (state.account && state.account.token) {
        return state.account.token || "";
      }

      // We still need localstorage for local development
      if (localStorage && localStorage.getItem("token")) {
        let sToken = localStorage.getItem("token") || "";
        if (sToken == "undefined") {
          return "";
        }
        return sToken;
      }

      return "";
    },
    getAccountFlagStudy: (_, getters) => {
      return getters.getAccountFlag("in_open_study");
    },
    getAccountFlag: (state) => (slug) => {
      if (!state.account) {
        return null;
      }
      return state.account.preferences[slug];
    },
    getArticleReadBounds(state) {
      if (!state.last_read) {
        return;
      }
      return state.lib_articles[state.last_read]
        ? state.lib_articles[state.last_read]["read-progress"]
        : false;
    },

    screenName(state) {
      return state?.account?.screen_name || false;
    },

    checkFollowing: (state) => (username) => {
      return state?.lib_following[username] || false;
    },

    isAdmin(state) {
      if (
        state.account &&
        state.account.policy &&
        state.account.policy.policies
      ) {
        return state.account.policy.policies.includes("spirit:developer");
      }

      return false;
    },

    isPro(state) {
      return state.account.is_pro;
    },

    isAlpha(state) {
      return state.account.is_alpha;
    },

    isBeta(state) {
      return state.account.is_beta || state.account.is_alpha;
    },

    isLoggedIn(state) {
      if (state.account) {
        return !!state.account.token;
      }
      return false;
    },
    isNotSignedIn(_, getters) {
      return !getters.isLoggedIn;
    },

    debugAllTopics(state) {
      return state.debugFlags.showAllTopics;
    },

    completedExcludedStep(state) {
      if (
        state.account &&
        state.account.onboarding &&
        state.account.onboarding.ios_publisher_exclusion_flow_completed
      ) {
        return state.account.onboarding.ios_publisher_exclusion_flow_completed;
      } else if (localStorage) {
        return (
          localStorage.getItem("ios_publisher_exclusion_flow_completed") ||
          "not-defined"
        );
      } else {
        return "not-defined";
      }
    },

    showProPromo(state) {
      let account = state.account;
      let hidePromoFor = 5; // Hide promo for X days from creation date
      let dateDifference = dayjs(account.details.created).diff(dayjs(), "day");
      let isLoggedIn = !!account.token;

      if (
        !account.is_pro &&
        dateDifference < -Math.abs(hidePromoFor) &&
        isLoggedIn
      ) {
        return true;
      }

      return false;
    },

    /**
     * 	Core Getters
     */

    getConfig(state) {
      return state.osConfig;
    },

    getPublishers(state) {
      return state.lib_publishers;
    },

    getTrendingTopics(state) {
      return state.lib_trending_topics;
    },

    getStatus(state) {
      return state.lib_status;
    },

    getMicroChallenges(state) {
      return state.lib_micro_challenges;
    },

    getCompletedChallenges(state) {
      return state.lib_completed_challenges;
    },

    getReportDates: (state) => {
      // console.log("Deprecated");
      return { since: state.lib_report.since, until: state.lib_report.until };
    },

    getD3GeoMap: (state) => {
      return state.lib_d3.geoMap || {};
    },

    getHistoryTopicCoverage: (state) => {
      return state.lib_history_topic_coverage;
    },

    getHistoryPublisherCoverage: (state) => {
      return state.lib_history_publisher_coverage;
    },

    getPagination(state) {
      return state.lib_pagination;
    },

    getTopicExtras: (state) => (topic) => {
      topic = topic || "all";
      return state.lib_topic_details[topic];
    },

    getHeadlineTopicData: (state) => (topic) => {
      if (state.lib_headline_topics_data[topic]) {
        return state.lib_headline_topics_data[topic];
      }
      return false;
    },

    getChartTopics: (state) => (topics) => {
      let _rtn = [];
      topics.forEach((topic) => {
        _rtn.push(state.lib_chart_topic[topic]);
      });
      return _rtn;
    },

    getReadNext(state) {
      return state.lib_read_next;
    },

    getReadNextData(state) {
      return state.lib_read_next_data;
    },
    getArticleTime(state) {
      return state.articleTimes;
    },
    getLastRead(state) {
      return state.last_read;
    },
    getHomeTopics(state) {
      return state.lib_home;
    },

    getUserLayout() {
      return "layout_a";
    },

    getThreadShareOverlay(state) {
      return state.threads_overlay;
    },
  },
  mutations: {
    setHtmlBuild(state, value) {
      state.htmlBuild = value;
    },

    /**
     * --------------------- V6 --------------------
     */
    setV6Sections(state, value) {
      state.v6Sections = value;
    },

    setV6Topic(state, value) {
      state.v6Topic = value;
    },

    setV6CleanLog(state, key) {
      Vue.set(state.v6CleanLog, key, new Date());
    },

    unsetV6CleanLog(state, key) {
      let aLog = { ...state.v6CleanLog };
      delete aLog[key];
      Vue.set(state, "v6CleanLog", aLog);
    },

    setNavHistory(state, value) {
      state.navHistory.push(value);
    },

    setTab(state, value) {
      state.tabs[value.code] = value;
    },
    /**
     * ___________________________________
     */

    timer(state, payload) {
      // console.log("UserEngine -- Committing: ", payload.slug, payload.timer);
      Vue.set(state.__timers, payload.slug, payload.timer);
    },

    /**
     * ___________________________________
     */

    chromeExtension(state) {
      state.chromeExtension = true;
      // Default the version before it new how to say its version...
      state.chromeExtensionVersion = state.chromeExtensionVersion || "0.9";
    },

    chromeExtensionVersion(state, payload) {
      state.chromeExtension = true;
      state.chromeExtensionVersion = payload;
    },

    addArticleReadStatuses(state, payload) {
      Vue.set(state.lib_read_statuses, payload.slug, payload.data);
    },

    setHomeTopics(state, payload) {
      state.lib_home = payload;
    },

    storeArticleTime(state, payload) {
      if (payload.time) {
        Vue.set(state.articleTimes, payload.type, payload.time);
      } else {
        Vue.delete(state.articleTimes, payload.type);
      }
    },

    addReadNext(state, payload) {
      state.lib_read_next_data = payload;
    },

    addReadNextStories(state, payload) {
      state.lib_read_next = payload;
    },

    updateReadNext(state, payload) {
      // console.log("Trying to set read status on:", payload);
      if (payload.type) {
        Vue.set(state.lib_read_statuses[payload.story], payload.article, {
          code: payload.type,
        });
      }
      state.last_read = payload.article;
    },

    updatePluginCheck(state) {
      state.hasPlugin = true;
    },

    articleURL(state, payload) {
      state.articleURL = payload;
    },

    updateShowModal(state, payload) {
      if (
        !state.hasApp ||
        (state.hasApp &&
          ((state.hasAppVersion >= 1.5 && state.minorAppVersion >= 6) ||
            state.hasAppVersion >= 1.6))
      ) {
        Vue.prototype.EventBus.$emit("modal:auth", "sign-up");
      } else {
        if (payload) {
          state.showAccountModal = payload;
        } else {
          state.showAccountModal = !state.showAccountModal;
        }
      }
    },

    updateIOSState(state, payload) {
      state.hasIOSApp = payload;
    },

    updateMini(state, payload) {
      state.hasMini = payload;
    },

    // full view override (like iFrame mode)
    updateMiniView(state, payload) {
      state.hasMiniView = payload;
    },

    updateAppCheck(state, payload) {
      state.hasApp = payload;
    },
    updateAppPlatform(state, payload) {
      state.hasAppPlatform = payload;
    },
    updateAppVersion(state, payload) {
      state.hasAppVersion = parseFloat(payload);
      state.minorAppVersion = parseFloat(payload.split(".")[2]);
    },

    updateChromeCheck(state, payload) {
      state.hasChrome = payload;
    },

    unsetAccount(state) {
      localStorage.removeItem("token");
      // Clone the object's properties
      state.account = { ...state.account_blank };
    },

    updateAccount(state, payload) {
      state.account = payload;
      if (localStorage) {
        localStorage.setItem("token", payload.token);
      }
    },

    updateStripe(state, payload) {
      state.account.subscription.stripe = payload;
    },

    updateSettings(state, payload) {
      // strange goings on... this is a hell of a clobber but watchers are being weird..
      Vue.set(state.account, "settings", payload);
      // console.log("Settings: ", this.getters.getAccount?.settings);
    },

    updateExcludedPublishers(state, payload) {
      state.account.preferences.excluded_publishers = payload;
    },

    /**
     * 	Core setters
     */

    addMicroChallenges: (state, payload) => {
      state.lib_micro_challenges = payload;
    },

    addCompletedChallenges: (state, payload) => {
      state.lib_completed_challenges = payload;
    },

    addReport: (state, payload) => {
      state.lib_report = payload.data;
    },

    addTrendingTopics: (state, payload) => {
      state.lib_trending_topics = payload.data;
    },

    addD3GeoMap: (state, payload) => {
      Vue.set(state.lib_d3, "geoMap", payload.data);
    },

    addHistoryTopicCoverage: (state, payload) => {
      state.lib_history_topic_coverage = payload.data;
    },

    addHistoryPublisherCoverage: (state, payload) => {
      state.lib_history_publisher_coverage = payload.data;
    },

    addArticle: (state, payload) => {
      Vue.set(state.lib_articles, payload.slug, payload.data);
    },

    addPublishers: (state, payload) => {
      state.lib_publishers = payload;
    },

    addStatus: (state, payload) => {
      state.lib_status = payload;
    },

    addFullTopic: (state, payload) => {
      Vue.set(state.lib_full_topics, payload.slug, payload);
    },

    addTopic: () => {
      throw "Deprecated: addTopic";
    },

    addTopicExtras: (state, payload) => {
      let topic_slug = payload.code || "all";
      Vue.set(state.lib_topic_details, topic_slug, payload);
    },

    addChartTopics: (state, payload) => {
      let topic_slug = payload.code || "";
      Vue.set(state.lib_chart_topic, topic_slug, payload);
    },

    addHeadlineTopicData: (state, payload) => {
      Vue.set(state.lib_headline_topics_data, payload.topic, payload.data);
    },

    setThreadShareOverlay(state, sUrl) {
      return Vue.set(state, "threads_overlay", sUrl);
    },

    updateFollowingUsername: (state, payload) => {
      Vue.set(state.lib_following, payload.username, payload.follow);
    },

    // LEGACY?
    updateReadStatus(state, payload) {
      let article = state.__library?.articles?.shelf[payload.article];
      if (article) {
        Vue.set(
          state.__library.articles.shelf[payload.article],
          "read_status",
          payload
        );
      }
    },

    setReadState(state, payload) {
      // console.log(`UserEngine -- SET: (${payload.slug})`, state.__readstate, payload.slug, payload);

      let oCurrent = state.__readstate[payload.slug] || {};
      if (!oCurrent.px || payload.px >= oCurrent.px) {
        Vue.set(state.__readstate, payload.slug, payload);
      }
      // else, don't backtrack 'read' stories back to glanced..
    },

    /**
     *  Updated setters (for new library model)..
     *  _______________________
     *
     *  New generic storage model...
     */
    logLibraryActivity(state, payload) {
      // Stamp an active request..
      let stamp = payload.active ? new Date().getTime() / 1000 : 0;
      Vue.set(state.__library._active, payload.url, stamp);
    },

    clearLibraryItem(state, endpoint) {
      Vue.set(state.__library[endpoint], "endpoint", endpoint);
      Vue.set(state.__library[endpoint], "shelf", {});
    },

    storeLibraryItem(state, payload) {
      // TTL: 3 minutes, for all endpoints.. (??)
      let expires =
        payload.expires || Math.round(new Date().getTime() / 1000) + 60 * 3;

      let store = {
        expires: expires,
        full: payload.full || false,
        book: payload.data,
      };

      // Patch our half configured library shelves..
      if (!state.__library[payload.endpoint].shelf) {
        Vue.set(state.__library[payload.endpoint], "shelf", {});
      }

      if (
        !payload.full &&
        state.__library[payload.endpoint].shelf[payload.item_key] &&
        state.__library[payload.endpoint].shelf[payload.item_key].full
      ) {
        // console.log(`Library : *** Refusing to overwrite full data with partial data :${payload.endpoint} :${payload.item_key}`);
        return;
      }

      // Write..
      //   console.log(`Writing: ${payload.endpoint}.${payload.item_key}: `, store);
      Vue.set(state.__library[payload.endpoint].shelf, payload.item_key, store);
    },

    updateDial(state, payload) {
      // Obs not practical but we haven't got optional expiries yet...
      let expires =
        payload.expires || Math.round(new Date().getTime() / 1000) + 60 * 5;

      if (state?.__library["dial"]?.shelf) {
        Vue.set(state.__library["dial"].shelf, "_", {
          expires: expires,
          full: true,
          book: payload,
        });
      }
    },

    /**
     *  v3 Page Layouts
     *  _______________________
     */
    setV3Layout(state, payload) {
      // console.log("Payload: ", payload);
      Vue.set(state.__v3.prepared, payload.path, payload.layout);
    },

    setV3HomeTEMP(state, payload) {
      Vue.set(state, "v3Home_TEMP", payload);
    },
  },
});
