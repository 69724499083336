<template>
  <section id="app" :class="{'is-mobile': hasMobile, 'is-desktop': !hasMobile, 'is-app': hasApp, 'is-web': !hasApp}" v-cloak>

    <!-- <Header/> -->
    <div class="header-group">
      
      <!-- <Header v-if="!hasApp || hasNewApp"/>
      <global-header v-else></global-header> -->

      <Header/>

      <!-- <TrendingTopics class="trending" v-show="!hasApp && !hasMini && hasTrends"/> -->
      <!-- <SectionJump/> -->
    </div>
    <div>
      <!-- <template v-if="!hasMini">
        <global-promo></global-promo>
      </template> -->
      <main class="main-content" :class="{'mini': hasMini}" v-if="isHTTP2xx">
        <keep-alive>
          <!-- <router-view :key="$dataEngine.routerKey($route)" :data-router-key-salt="$dataEngine.routerKey($route)"></router-view> -->
          <router-view></router-view>
        </keep-alive>
      </main>
      <main v-else>
        <div class="outer-404">
          <div class="inner-404">
            <p class="emoji">:(</p>
            <h1>Yikes! Sorry.<br> This page has expired.</h1>
            <p>We're only a small team so we have to archive things eventually, sorry.</p>
            <router-link to="/" class="button">Read recent stories.</router-link>
            <p>Obviously, if you think this is a mistake, <router-link to="/contact">please let us know!</router-link></p>
          </div>
        </div>
      </main>
      
      <global-signup></global-signup>
      <global-footer v-if="!hasMini"></global-footer>
      <!-- <div v-if="hasApp || hasChrome" class="faux-footer">&nbsp;</div> /// WHY? -->
      <article-inbound></article-inbound>
      <message-inbound></message-inbound>
      <excluded-settings></excluded-settings>
      <Goal/>
      <!-- <QuickNav/> -->
      <Coaching/>
      <TabBar :visible="true"/>
    </div>
    <!-- Footer // -->
    <div id="fb-root"></div>
    <!-- Globals // -->
    <handle-read-article></handle-read-article>
    <handle-subscribe></handle-subscribe>
    <handle-threads-overlay v-if="threadShareOverlay"></handle-threads-overlay>
    <!-- // End Globals -->
    <!-- // MQHack / CSS/JS Bridge -->
    <div id="mq-bridge">
      <div id="notch"></div>
    </div>

    <Auth/>
    <GoPro/>
    <QR />
    <Waitlist />
    <Onboarding />
  </section>
</template>

<script>
import "styles/app.scss";
// import VuePullRefresh from 'vue-pull-refresh';
import API from "app/axios.js";
// REMOVED (July 2023) -- slow and no-one looks at it anyway! ... if re-added, sample or exclude GoogleBot!
// import bugsnagClient from "app/handlers/bugsnag/bugsnag";

import GlobalSignup from "./components/SignUp";
import GlobalFooter from "./components/Footer";
// import GlobalPromo from "./components/Promo";

import HandleReadArticle from "./handlers/ReadArticle";
import HandleSubscribe from "./handlers/SubscribeHandler";
import HandleThreadsOverlay from "./handlers/ThreadsOverlay";

import ArticleInbound from "./modules/Article/ArticleInbound";
import MessageInbound from "./modules/Message/MessageInbound";

import ExcludedSettings from "./components/ExcludedPublishers/ExcludedSettings";

import Header from "./components/_v5/base/Header";
import Auth from '@/components/_v5/modals/Auth.vue';
import GoPro from '@/components/_v5/modals/GoPro.vue';
import QR from '@/components/_v5/modals/QR.vue';
import Waitlist from '@/components/_v5/modals/Waitlist.vue';
import Onboarding from '@/components/_v5/modals/Onboarding.vue';

import Goal from '@/components/_v6/modal/Goal.vue';

// import QuickNav from '@/components/_v6/QuickNav.vue';
import TabBar from 'app/components/_v6/elements/TabBar.vue';
import Coaching from 'app/components/_v6/Coaching.vue';
// import SectionJump from 'app/components/_v6/SectionJump.vue' 


// v4
// import Header from "./components/v4/Header";
// import TrendingTopics from "./components/v4/TrendingTopics";

export default {
  name: "App",
  components: {
    // VuePullRefresh,
    GlobalSignup,
    GlobalFooter,
    // GlobalPromo,
    ArticleInbound,
    MessageInbound,
    HandleReadArticle,
    HandleSubscribe,
    HandleThreadsOverlay,
    ExcludedSettings,
    Header,
    // Header,
    // TrendingTopics,
    Auth,
    GoPro,
    QR,
    Waitlist,
    Onboarding,
    Goal,
    // QuickNav,
    TabBar,
    Coaching,
    // SectionJump,
  },
  data() {
    return {
      hideOSIntro: false,
      inboundCollected: false,
      refreshConfig: {
        startLabel: 'Pull to refresh',
        readyLabel: 'Release to refresh',
        loadingLabel: 'Refcd reshing...'
      },
      sBuild: '',
      timers: {},
    };
  },
  mounted() {
    this.deviceLog("Device: " + navigator.vendor);

    /**
     * Allow user/URL control of view style (hmm)
     */
     if (['iframe', 'stats'].includes(new URLSearchParams(window.location.search).get('view'))) {
      this.$store.commit('updateMiniView', true);
    }

    this.initialisePlugins();

    /**
     * Update some root css
     */
    this.updateSectionClass(this.routeSection);

    /**
     * Intialise App Binding Handler.. 
     */
    this.initialiseAppBindings();
    
    /**
     * Run an app handshake (iOS v2, not yet in Android (~April 2020)) 
     */
    this.runDeviceHandshake();

    /**
     * Async/Promised, so start quick.. (to hopefully save 2x intercom hits)
     */ 
    this.checkChromeExtension();

    /**
     * Setup the overlays, listners etc.. for the 'threads' model
     */
    this.setupThreadsMechanics();

    /**
     *  Initialise the user-account with the token in the page source (if given)
     */
    // console.log(`Build: `, {
    //   '_os_user:build': window._os_user?.build?.client,
    //   '_os_build': window._os_build,
    //   '_os_user:token': window._os_user?.token,
    //   'localestorage:token': localStorage?.getItem('token'),
    // });

    let token = '';
    if (window._os_user && (token = window._os_user.token)) {
      this.sBuild = window._os_user?.build?.client || window._os_build || '_user_build_error_';
      API.getAccount(token);
    } else if (localStorage && localStorage.getItem('token')) { // Needed for local development
      // this.sBuild = localStorage.getItem('build') || window._os_build || '_local_build_error_';
      // removed `localStorage.getItem('build')` -- no idea where this is ever set but it's wrong/broken
      this.sBuild = window._os_build || '_local_build_error_';
      API.getAccount(localStorage.getItem('token'));
    } else {
      this.sBuild = window._os_user?.build?.client || window._os_build || '_guest_build_error_';
      // We still need to trigger everything that's listening for an account load, even knowing we're logged out.
      this.EventBus.$emit('api:account');
    }
    this.$store.commit('setHtmlBuild', this.sBuild);


    /**
     * Accept store priming data (for SEO grift, speed and shizzle)
     */
    if (window._os_prime) {
      let oPrime = window._os_prime
      console.log(`[StorePrime] -- Data received: `, oPrime);


      // story nodes..
      if (oPrime.home) {
        if (oPrime.home.edition) {
          this.$store.commit('storeLibraryItem',{
            endpoint: 'edition_v6', 
            item_key: '_',
            full: true,
            data: oPrime.home.edition,
          });
          console.log(`[StorePrime] -- Stored: `, oPrime.home.edition);
        }

        // headline & trends -- but these aren't library items.. so... leave them for now?
        if (oPrime.home.edition) {
           this.$store.commit('storeLibraryItem',{
            endpoint: 'edition_v6', 
            item_key: '_',
            full: true,
            data: oPrime.home.edition,
          });
        }
        if (oPrime.home.edition) {
           this.$store.commit('storeLibraryItem',{
            endpoint: 'edition_v6', 
            item_key: '_',
            full: true,
            data: oPrime.home.edition,
          });
        }
      }

      // stories..
      if (oPrime.stories) {
        Object.entries(oPrime.stories).forEach(([slug, data]) => {
          console.log(`[StorePrime] -- Storying story: ${slug}`, data);
          this.$store.commit('storeLibraryItem',{
            endpoint: 'story_v3', 
            item_key: slug,
            full: true,
            data: data,
          });
        });
      }

      if (oPrime.nodes) {
        Object.entries(oPrime.nodes).forEach(([slug, data]) => {
          console.log(`[StorePrime] -- Storying story node: ${slug}`, data);
          this.$store.commit('storeLibraryItem',{
            endpoint: 'story_node_v3', 
            item_key: slug,
            full: true,
            data: data,
          });
        });
      }

      // story nodes..
      if (oPrime.answers) {
        Object.entries(oPrime.answers).forEach(([slug, data]) => {
          console.log(`[StorePrime] -- Storying answers node: ${slug}`, data);
          this.$store.commit('storeLibraryItem',{
            endpoint: 'answers', 
            item_key: slug,
            full: true,
            data: data,
          });
        });
      }

    }





    window.setInterval(() => {
      // console.log("Build: refreshing account");
      API.getAccount(this.$store.getters.getToken);
    }, 1000 * 60 * 5); // 5 minutes

    /**
     * Fetch editions to have them ready for the dropdown selector
     */
    
    // API.getEditions();

    /**
     * Lister for user coming back to this window for some reason
     */
    document.addEventListener("visibilitychange", () => {
      this.handleInbound();
    });

    /**
     * 	Intialise Intercom
     */
    // this.setIntercom();

    /**
     *  Other legacy listeners -- need tidying away somewhere.. 
     */
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });

    this.EventBus.$on("deviceLog", (payload) => {
      this.deviceLog(payload);
    });

    this.EventBus.$on("resetReadMoreTime", () => {
      this.inboundCollected = false;
    });

    this.EventBus.$on("trackArticleAction", (payload) => {
      this.trackArticleAction(payload);
    });
    
    this.EventBus.$on("navigate", (payload) => {
      this.universalNavigation(payload);
    });

    this.EventBus.$on("social:share", (payload) => {
      this.socialShare(payload);
    });
    
    /**
     * Styling the header..
     */
    // Keep any eye, twice a second, for the first 30 seconds..
    let hStartupWatch = window.setInterval(() => {
      // set the header colour..
      this.matchHeaderBG();
    }, 500);
    // .. and then stop and rely on the router-watch.
    window.setTimeout(() => {
      window.clearInterval(hStartupWatch);
    }, 30 * 1000);

    // disable "long-press" on *MOBILE* anything but text areas 
    document.addEventListener("contextmenu", (e) => {
      // not on desktop! don't be a dick.
      if (!this.hasApp) {
        return;
      }
      // not on text-areas and inputs (copy & paste)
      if (['input', 'textarea'].e?.target?.tagName?.toLowerCase()) {
        return;
      }
      // but otherwise... turn off long-presses on mobile.
      e.preventDefault()
    });

    document.addEventListener("paste", (e) => {
      let paste = (e.clipboardData || window.clipboardData).getData("text");
      // console.log([e.target]);
      if (['input'].includes(e.target.tagName.toLowerCase()) || (e.target.isContentEditable)) {
        return;
      }
      if (paste.length == 16) {
        // assume it's a slug? (probably not great for passwords etc?)
        let sUrl = `/story/${paste}`;
        console.log(`Navigating: ${sUrl}`);
        console.log(this, this.$router);
        this.$router.push(sUrl);
        e.preventDefault();
      }
    });
  },
  methods: {
    /**
     * Device Bindings
     * ______________________________________________
     */
    receiveMessage(event) {
      // Check it's a local event (devices inject into the page..)
      if (event.origin != document.location.origin){
        this.deviceLog("Ignoring origin: " + event.origin);
        return;
      }

      // Check it's a valid event.. 
      let eventName = event.data.oneSubEvent;
      if (!eventName){
        this.deviceLog("Ignoring blank event name: " + event.data.oneSubEvent + " // " + JSON.stringify(event.data));
        return;
      }

      // Log back to the device debugger..  
      this.deviceLog("Message received: Origin: " + event.origin + ", Message: " + JSON.stringify(event.data));
      
      switch (eventName){

        case 'didSelectRouteHome':
          if (this.$route.path == '/') {
            // On home already, scroll back to top
            this.EventBus.$emit('v5:reload', {
              mode: 'user'
            });
          } else {
            // Not on home, route home
            this.$router.push(`/${event.data.mode}`);
          }
          
          break;

          // if (this.$store.getters.isAlpha) {
          //   // this.deviceLog("Device routing home (Alpha): " + JSON.stringify(this.$store.getters.getAccount));
          //   this.$router.push(`/v3${event.data.mode}`);
          //   break;
          // } else {
            // this.deviceLog("Device routing home (Beta/Live): " + JSON.stringify(this.$store.getters.getAccount));
            // this.deviceLog("New Message received: Origin: " + event.origin + ", Message: " + JSON.stringify(event.data));
            // this.$router.push(`/${event.data.mode}`);
            // break;
          // }
        case 'deviceSetToken' :
          if (event.data.token) {
            this.deviceLog("Device: Set token: -- " + event.data.token.substr(0,12));
            // Initiate account load.. (Don't take the token at face value, defer to the API!)
            API.getAccount(event.data.token);
          } else {
            this.deviceLog("Device: Clear token **");
            this.$store.commit('unsetAccount');
          }
          // But we're definitely in the confines of the app, so update the UI accordingly.. 
          this.$store.commit("updateAppCheck", true);
          break;

        case 'deviceSetVersion' :
          this.deviceLog("Setting device version! -- " + event.data.version);
          // But we're definitely in the confines of the app, so update the UI accordingly.. 
          this.$store.commit("updateAppVersion", event.data.version);
          break;

        case 'toggleNavigation' :
          this.deviceLog("Toggle navigation (hopefully (handled elsewhere in Vue))");
          // Handled elsewhere
          break;

        case 'guestModeSet':
          this.deviceLog("Guest Mode: Checking excluded publisher settings **");
          if (!localStorage.getItem('ios_publisher_exclusion_flow_completed')) {
            this.deviceLog("Guest Mode: Publisher flow is not complete. **");
            this.EventBus.$emit("handleExcludedSettings", {
              showExcluded: true
            });
          } else {
            this.deviceLog("Guest Mode: Publisher flow already complete. **");
          }
          break;

        // Appple notifications
        case 'setNotificationState':
          if (event.data.device == 'ios') {
            this.$userEngine.setPref('onesub.devices.ios.apn.state', event.data.state);
            this.$userEngine.setPref('onesub.devices.ios.apn.stamp', new Date());
            this.$userEngine.setPref('onesub.devices.ios.apn._devicetoken', event.data.apn);
          }
          if (event.data.device == 'android') {
            this.$userEngine.setPref('onesub.devices.android.apn.state', event.data.state);
            this.$userEngine.setPref('onesub.devices.android.apn.stamp', new Date());
            this.$userEngine.setPref('onesub.devices.android.apn._devicetoken', event.data.apn);
          }

          // Notify CTA component state that notifications were enabled
          if (event.data.state == 'authorised') {
            this.EventBus.$emit("notificationsEnabled", {
              data: {}
            });
          }
          
          // console.log("Remote notification token updated", "State: ", event.data.state, " APN: ", event.data.apn)
          this.deviceLog("Remote notification token updated", "State: ", event.data.state, " APN: ", event.data.apn);
          break;

        case 'remoteNotificationOpened':
          // console.log("Remote notification opened", "Data: ", event.data.data)
          this.deviceLog("Remote notification opened", "Data: ", event.data.data);
          break;

        case 'setMode':

          // Removed: 18 Sep 2024 -- the browser *should* handle the mode without interference from the device
          //                      -- BUT ... what this does is set, permenantly, the user's preference to whatever the device happens to think in this moment..

          // console.log("Setting light/dark mode from device", "Data: ", event.data.mode)
          // this.deviceLog("Setting light/dark mode from device", "Data: ", event.data.mode);
          // this.$userEngine.setPref('layout.theme', event.data.mode);
          break;

        default : 
          this.deviceLog("Can't handle: " + eventName);
          break;
      }
    },

    deviceMessage(sMode, oData = {}){
      this.EventBus.$emit("appCallback", {
        type: sMode,
        data: oData || {}
      });
    },
    
    deviceLog(sMessage){
      this.deviceMessage('consoleXCode',{
        message: sMessage,
      });
    },

    runDeviceHandshake(){
      this.deviceMessage('beginHandshake', {
        token: this.$store.getters.getToken,
      });
      /**
       * LEGACY ... Initialise the app in the correct mode.. (Chrome, Covid, App (Native))
       * --- Actually (Sept 2022) ... it's pretty critical.  Neither app has ?mode= on.. 
       */
      this.checkApp();
    },

    /**
     * Legacy -- needs review (April 2020)
     * ______________________________________________
     */

    onRefresh() {
      return new Promise(function (resolve) {
        setTimeout(function () {
          resolve();
          location.reload();
        }, 1000);
      });
    },
    
    trackArticleAction(payload) {
      // Only track opt-in, real users..
      if (!this.loggedIn) {
        // console.log('Tracking: Not logged in');
        return;
      }
      
      // time: optional
      payload.time = payload.time || 0;

      // story: is optional
      let story = (payload.story === undefined) ? '' : 
         ( typeof payload.story === 'object' ) ? payload.story.slug : payload.story;

      // platform, version, scope (browser) defaults:
      let platform =  (this.hasMobile) ? "mobile" : "desktop";
      let version = this.getVersionNumber;
      let scope = 'browser';

      // unless in app:
      if (this.hasApp) {
        platform = navigator.vendor.match('/Google/') ? 'android' : 'apple';
        version = this.$store.getters.hasAppVersion;
        scope = 'app';
      }

      /**
       * ----------- SEND -------
       */
      let api_payload = {
        article: payload.article.slug,
        story: story,
        state: payload.state,
        time: payload.time,
        scope: scope,
        version: version,
        platform: platform
      };
      // console.log("Tracking: ", api_payload);
      // this.deviceLog("Tracking: ....", api_payload);

      // don't hammer the server in the first 5 seconds
      // (October, SEO fiddling..)
      if (payload.time < 5) {
        return;
      }

      // and give up after 60s * 3m = 180s too -- no-one cares
      if (payload.time > (60 * 3)) {
        return;
      }

      // console.log(`[Sync|App.vue] ${payload.time} `, api_payload);

      API.post('/track/article/read',api_payload).then(() => {
        // console.log(response.data);
      });
      // .catch((error) => {
      //   bugsnagClient.notify(error);
      // });

    },
    
    handleInbound() {
      if (!this.loggedIn) {
        return;
      }
      if (document.visibilityState == "hidden") {
        return;
      }
      this.$store.commit("storeArticleTime", {
        type: "inbound",
        time: new Date()
      });
      this.EventBus.$emit("inboundCollected");
    },

    socialShare(payload) {
      // allow strings as functions for late interpolation.. 
      // ---------------------------------------------------
      // Early: payload = { text: `Foo ${bar}` }
      // Late : payload = { text: () => `Foo ${bar}` }
      // ---------------------------------------------------
      // Early interpolation can happen before $store is filled
      // for instance, so "My name is undefined" happens.
      // ... late allows you to add ${store.name} late, (JIT)
      // 
      let text = typeof payload.text == 'function' ? payload.text() : payload.text;
      let tweet = typeof payload.tweet == 'function' ? payload.tweet() : payload.tweet;
      let link = typeof payload.link == 'function' ? payload.link() : payload.link;

      // make sure stuff's encoded properly.. 
      text = typeof text == 'string' ? encodeURIComponent(text) : text;
      tweet = typeof tweet == 'string' ? encodeURIComponent(tweet) : tweet;
      link = typeof link == 'string' ? encodeURIComponent(link) : link;

      // console.log("Social payload: ", {
      //   payload: payload,
      //   text: text,
      //   tweet: tweet,
      //   link: link,
      // });
      

      switch(payload.platform) {
        case 'facebook':
            this.universalNavigation(`https://www.facebook.com/sharer/sharer.php?u=${link}`);
          break;
        case 'linkedin':
            this.universalNavigation(`https://www.linkedin.com/sharing/share-offsite/?url=${link}`);
          break;
        case 'twitter':
            // use specific tweet text if provided; fallback to the text..
            text = tweet ? tweet : text;
            // append the link into the body of the tweet
            // .. at the end, to be replaced with an OG card if available..
            link = link ? ' ' + link : '';
            this.universalNavigation(`https://twitter.com/intent/tweet?text=${text}${link}`);
          break;
        default:
          console.error("Unrecognised social platform: ", payload);
      }
    },

    universalNavigation(payload) {
      // accept string or object
      payload = typeof payload == 'string' ? {link: payload} : payload;

      // get the current link mode
      payload.mode = this.$store.getters.linkMode(payload.mode);

      // console.log('Payload:::::::', payload);

      // switch & perform (callback|navigate|window)
      switch (payload.mode) {
        case 'callback': // most times this is the same as navigating.. :shrug:
        case 'navigate':
          // route or navigate..
          if (this.isRoutable(payload.link)){
            this.$router.push(payload.link);
          } else {
            top.document.location.href = payload.link;
          }
          break;
        case 'window': 
          window.open(payload.link).focus();
          break;
      }
    },

    isRoutable(href) {
      return this.$router.resolve(href)?.resolved?.matched?.length;
    },

    checkBuildReload() {
      let sAPIBuild = this.$store.getters.getAccount?.build?.client;
      // console.log(`Build Management. Source: ${this.sBuild}, API: ${sAPIBuild}`);

      if (this.sBuild.match(/_error_/)) {
        // console.warn(`Build Management. Bailing. Error in current build ID: ${this.sBuild}`);
        return;
      }

      if (!this.sBuild || !sAPIBuild) {
        // console.log(`Build Management. Bailing because something's blank.. which is ambigious and probably a bug elsewhere`);
        return;
      }

      // mismatch between the html & the API ... reload required.
      if (this.sBuild != sAPIBuild){
        let sUrlBuild = this.$route.query.build;
        if (sUrlBuild == sAPIBuild) {
          // console.log(`Build Management: Loop Stop. Reload attempted to ${sUrlBuild}. API expects ${sAPIBuild}`);
          return;
        }
        // don't work with undefineds.. 
        if (!sAPIBuild) {
          return;
        }
        // grab the current query string & add in / overwrite the build id
        let oQuery = this.$route.query;
            oQuery['build'] = sAPIBuild;
        // compile a new URL
        let sToUrl = this.$route.path + '?' + Object.entries(oQuery).map(([k, v]) => `${k}=${v}`).join('&');

        // console.warn(`Build Management: Going to -- `, sToUrl);
        document.location.href = sToUrl;
        return;
      }
    },

    initialisePlugins(){
      // probably unneccasary outside `npm run build`
      window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }
    },

    initialiseAppBindings() {
      // Inbound:
      window.addEventListener("message", this.receiveMessage, false);

      this.EventBus.$on('device:haptic', (note) => {
        // hack (backwards compatibility)
        let notesTypes = {
          success: 'notification',
          warning: 'notification',
          error: 'notification',
          light: 'impact',
          medium: 'impact',
          heavy: 'impact',
          rigid: 'impact', 
          soft: 'impact',
          selection: '',
        };

        if (!notesTypes[note]) {
          console.error(`Invalid haptic note: ${note}`);
          return;
        }

        this.deviceMessage('hapticsCallback', {
          type: notesTypes[note], 
          note: note,
        });
        // this.EventBus.$emit('appCallback', {
        //   type: ,
        //   data: {
        //     note: note
        //   },
        // });
      });

      // relay to container app
      this.EventBus.$on('api:account', () => {
        // Version reload
        this.checkBuildReload();
        // App callbacks
        this.EventBus.$emit('appCallback', {
          type: 'authCallback',
          data: this.$store.getters.getToken, // only token -- app should re-fetch
        });
        // other things to check on account load..
        this.checkThreadHash();
        // load challenges, if we are logged in
        if (this.loggedIn) {
          API.getChallenges();
          // set the build value into settings..
          this.$userEngine.setPref('build', this.sBuild);
        }
        
        
        // V3!?
        // if (this.$store.getters.isBeta) { // && this.$store.getters.hasApp) {
        //   if (this.$route.path == '/') {
        //     this.$router.push('/v3');
        //   }
        // }

        // V5 !! -- BETA & GUESTS go v5!
        // See also: router.js `MIGRATE_MODE`


        // if ( (this.$store.getters.isBeta) || (!this.$store.getters.isLoggedIn)) {
        //   if (this.$route.path == '/') {
        //     this.$router.push('/v5');
        //   }
        // }
      });

      // Outbound: 
      this.EventBus.$on("appCallback", (payload) => {
        // Chrome callbacks
        let aDevInstances = [
          'mnmjafgjmogfmdklhmdhghnghdoiohcg', // Active: JM - iMacPro         (Sep2020)
          'lmjfagnnkmginlijpmohogfbfnmoanpg', // Active: JM - MacBookPro2020  (Sep2020)
          'ieiofpfabikceffneghpniaodepfoamn', // Active: JS - MacBookPro      (Sep2020)
        ];
        let oChromePayload = {mode:'onesub:' + payload.type, data: payload.data};

        // Try all dev instances..
        aDevInstances.forEach(sDevToken => {
          try{ 
            window['chrome']?.runtime?.sendMessage(sDevToken, oChromePayload);
          } catch (e){
            //
          }
        });

        // LIVE - DON'T TOUCH! - PAS TOUCHÉ !! // 
        try {
          window['chrome']?.runtime?.sendMessage('nfknmjflaoenbigbfmijdpcfgbnbhbgj', oChromePayload);
        } catch (e){
          //
        }
        
        
        // iOS Callbacks
        try {
          // eslint-disable-next-line
          webkit?.messageHandlers[payload.type]?.postMessage(payload.data);
        } catch (err) {
          // console.log(err);
        }

        // Android Callbacks
        try {
          if (typeof Android !== 'undefined') {
            if(payload.type == 'storyCallback') {
              // eslint-disable-next-line
              Android[payload.type](JSON.stringify(payload.data));
            } else {
              // eslint-disable-next-line
              Android[payload.type](JSON.stringify(payload.data));
            }
          }
        } catch (err) {
          // console.log(err);
        }
      });
    },
    
    checkApp() {
      
      // This whole method will be deprecated.  (Leave in for Android, till app updated..)
      // return; 

      /* eslint-disable */
      let urlParams = new URLSearchParams(window.location.search);

      // Let's check if the we're in the app. This is tested via ?mode=app on the domain.
      if (urlParams.get("mode") == "app") {
        this.$store.commit("updateAppCheck", true);
      }


      // cache the platform locally.. 
      if (urlParams.get("platform")) {
        // mode= seems to be a chrome legacy thing?
        window.localStorage.setItem('platform', urlParams.get("platform") ||  urlParams.get("mode") || '');
      }
      let sPlatform = window.localStorage.getItem('platform');
      


      if (sPlatform == "ios") {
        this.$store.commit("updateIOSState", true);
        
        // infer ?mode=app from ?platform=ios
        this.$store.commit("updateAppCheck", true);
        this.$store.commit("updateAppPlatform", 'apple');
      }

      if (sPlatform == "android") {
        // there doesn't seem to be a state?
        // this.$store.commit("updateIOSState", true);
        
        // infer ?mode=app from ?platform=android
        this.$store.commit("updateAppCheck", true);
        this.$store.commit("updateAppPlatform", 'android');
      }

      // Check "Chrome" app..
      if (sPlatform == "chrome" ) {
        // Don't localStorage because it's transient within iframe
        this.$store.commit("updateChromeCheck", true);
        this.$store.commit("updateAppPlatform", 'chrome');
      } 
      
      /* eslint-enable */
    },
    
    setIntercom() {
      if (this.timers['Intercom']) {
        window.clearTimeout(this.timers['Intercom']);
      }
      const iDelay = 1;
      // console.log(`Considering Intercom in ${iDelay}s`);
      this.timers['Intercom'] = window.setTimeout(this.setIntercomDO, 1000 * iDelay);
    },

    setIntercomDO() {
      // console.log("Considering Intercom..");
      const bDryRun = false; // (process?.env?.NODE_ENV != 'production');

      // Don't run in the chrome OR native apps!
      if (this.hasChrome || this.hasApp){
        // console.log(`Considering Intercom: No {Chrome:${this.hasChrome}, App:${this.hasApp}}`);
        return;
      }

      // Instantiate Intercom (once)
      // if (!window['Intercom'] && !bDryRun){
      //   this.instantiateIntercom();
      // }

      // Build our Intercom Object
      let payload = {
        app_id: "hcca84qj",
        hide_default_launcher: this.hasMobile || this.hasMini 
      };

      let oUser = this.$store.getters.getAccount;

      // What release are we on?
      let sRelease = 'Live';
      if (this.$store.getters.isBeta) sRelease = 'Beta';
      if (this.$store.getters.isAlpha) sRelease = 'Alpha';
       
      payload = {
        app_id: "hcca84qj",
        user_id: oUser.details.id,
        name: (oUser.details.firstName) ? `${oUser.details.firstName} ${oUser.details.lastName}` : false,
        email: oUser.details.email,
        id: oUser.details.id,
        created_at: (new Date(oUser.details.created).getTime() / 1000),
        release: sRelease,
        pro: this.$store.getters.isPro,
        pro_slug: oUser.subscription?.slug,
        pro_plan: oUser.subscription?.plan,
        pro_acquirer: oUser.subscription?.acquirer,
        pro_since_at: oUser.subscription ? (new Date(oUser.subscription.since).getTime() / 1000) : null,
        pro_renews_at: oUser.subscription ? (new Date(oUser.subscription.renews).getTime() / 1000) : null,
        pro_tenure: oUser.subscription?.tenure,
        pro_ltvrgbp: oUser.subscription?.ltvrgbp,
        version: this.getVersionNumber,
        email_alerts_on: oUser.details.updatesEmailOn ? true : false,
        email_alerts_timing: oUser.details.updatesEmailTiming,
        email_alerts_frequency: oUser.details.updatesEmailFreq,
        // in_open_study: oUser.preferences.in_open_study,
        threads_request: oUser.preferences.threads_request,
        threads_active: oUser.preferences.threads_active,
        threads_banned: oUser.preferences.threads_banned,
        threads_total: oUser.stats?.threads_total,
        threads_published: oUser.stats?.threads_published,
      };

      if (oUser.hmac) {
        // Spirit is (incorrectly) throwing broken hmacs for logged out users..
        let hmac_blank = "be641f818f592b4f2a6ed2af334ae528ab9ad9b133af6094b4ba3d6cf82db027";
        let hmac = (oUser.hmac != hmac_blank) ? oUser.hmac : false;
        if (hmac){
          payload.user_hash = hmac;
        }
      }

      // Filter what we have so far.. 
      Object.keys(payload).forEach((k) => (!payload[k]) && delete payload[k]);

      // Decide whether to show/hide the launcher (hide on App)
      // if (this.hasMini || this.hasMobile){
      payload.hide_default_launcher = true;
      // }

      // Decide if Chrome Ext is installed (only if in chrome)
      let iChromeExtensionVersion = this.$store.getters.chromeExtensionVersion
      if ( iChromeExtensionVersion ){
        payload.chrome_extension = true;
        payload.chrome_extension_version = iChromeExtensionVersion;
      }

      if (!window.Intercom || bDryRun) {
        return;
      }

      // Set the "boot" or "update" action (based on whether it's already booted)
      let sAction = window.Intercom.booted ? 'update' : 'boot';
      
      if (sRelease != 'Live'){
        // console.log("Intercom: " + sAction, payload );
      }
      // Fire Intercom!
      window.Intercom(sAction, payload);
    },

    instantiateIntercom(){
      (function() {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function() {
            i.c(arguments);
          };
          i.q = [];
          i.c = function(args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function() {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/hcca84qj";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    },

    checkChromeExtension() {
      // eslint-disable-next-line
      if (typeof window?.chrome?.runtime?.sendMessage === 'function'){
        [
          'lmjfagnnkmginlijpmohogfbfnmoanpg',
          'nfknmjflaoenbigbfmijdpcfgbnbhbgj',
        ].forEach(extensionId => {
          // Check 'installed'
          // Check 'version' (>=0.10)
          // console.log("Checking for Chrome Extension -- Sending message... ", extensionId);
          window.chrome.runtime.sendMessage(extensionId,{
            mode:'onesub:extension',
          }, (oResponse) => {
            if (oResponse) {
              // console.log("Extension present: ", oResponse);
              this.$store.commit('chromeExtension', true);
              this.$store.commit('chromeExtensionVersion', oResponse.version);
            }
          });
        });
      }
    },
    updateSectionClass(sNew){
      /**
       * Maintain a `section-foo` class for the route
       */
      document.body.classList.forEach(s => {
        if (s.match(/^section-/)) {
          document.body.classList.remove(s);
        }
      });
      document.body.classList.add('section-' + (sNew||'root'));
      
      /**
       * Manage any `route-foo` classes specified in routing config
       */
      document.body.classList.forEach(s => {
        if (s.match(/^route-/)) {
          document.body.classList.remove(s);
        }
      });
      // and add new
      this.$route.meta?.display?.body_class?.forEach(s => {
        document.body.classList.add(`route-${s}`);
      })
      // --------
    },
    matchHeaderBG(){
      // try {
      //   // promo
      //   let eTarget = false;
      //       eTarget = eTarget || document.querySelector('.promo-primary.mode-expanded');
      //       eTarget = eTarget || document.querySelector('.main-content section');
      //   let sColor = 'none';
      //   if (eTarget) {
      //     sColor = window.getComputedStyle(eTarget).backgroundColor;
      //     let aChannels = sColor.replace(/.*\((.*)\).*/,'$1').split(', ');
      //     if (aChannels.length == 4){
      //       let iAlpha = parseFloat(aChannels[3]);
      //       if (iAlpha < 200) {
      //         // console.warn('Ignoring translucent background');
      //         sColor = 'none';
      //       }
      //     }
      //   }
      //   Array.from(document.querySelectorAll('header > div')).forEach(e => {
      //     e.style.backgroundColor = sColor;
      //   });
      // } catch (e) {
      //   // console.log('Failed to find the header :shrug:', e);
      // }
    },

    setupThreadsMechanics() {
      // listen to a share events.. 
      this.EventBus.$on('thread:share', (payload) => {

        // active participant? (invite has matured)
        let bActive = this.$store.getters.hasThreads;
        if (!bActive) {
          // console.log('Not threads-activated');
          return;
        }

        // mobile? let's 
        if (this.hasApp || this.hasMobile){
          let link = payload.link || '';
          let thread = payload.thread || '';
          let story = payload.story || '';
          this.$router.push(`/share?link=${link}&thread=${thread}&story=${story}&back=router`);
          return;
        }

        this.$store.commit('setThreadShareOverlay', payload);
        [document.body, document.documentElement].forEach(e => {
          // console.log('stopping scroll');8
          e.style.overflow = 'hidden';
          e.style.overflowY = 'hidden';
          window.scrollTo(0, 0);
        });
      });

      this.EventBus.$on('thread:close-overlay', () => {
        this.$store.commit('setThreadShareOverlay', false);
        [document.body, document.documentElement].forEach(e => {
          e.style.overflow = '';
          e.style.overflowY = '';
        });

        // Emit close to the apps
        this.EventBus.$emit('appCallback', {
          type: 'closeThreadOverlay',
        });
      });


      // run on hashchange
      window.addEventListener('hashchange', this.checkThreadHash);

      // run once at launch
      this.checkThreadHash();
    },

    checkThreadHash() {
      let h = document.location.hash.substring(1);
      if (h.match(/^share=/)){
        // it's our hash, clear it...
        document.location.hash = '';
        this.EventBus.$emit('thread:share', {
          link: h.substring(6),
          on: true,
        });
      }
    },

  },
  watch: {
    account(to, from) {
      // refresh or new user (signin, signout etc.)
      let bSignInEvent = to?.token != from?.token;

      // trigger a global event..
      this.EventBus.$emit('api:account', {
        reauth: bSignInEvent,
        to: to,
        from: from,
      });
    },
    $route(to) {

      // console.log('Routing: ', from, to);
      // console.log('Route Meta: ', to.meta);

      // manage 'mini' context (now also a router variable)
      this.$store.commit('updateMini', to.meta?.mini);

      // tell intercom about the navigation.. 
      // setTimeout(() => {
      //   if (!this.hasMini && window.Intercom) {
      //     window.Intercom("update");
      //   }
      // }, 500);


      // update the meta, title etc..
      document.querySelector('meta[property="og:url"]') ? document.querySelector('meta[property="og:url"]').setAttribute("content", document.location.href): false;
      document.querySelector('meta[name="twitter:site"]') ? document.querySelector('meta[name="twitter:site"]').setAttribute("content", document.location.href) : false;
      

      // disabled temporarily -- seems to be overwriting our Google SERPs?
      // if(!(this.$route.params.story || this.$route.params.article || this.$route.name == 'Topic')) {
      //   let sTitle = 'OneSub - A better way to read the news.';
      //   window.document.title = sTitle;
      //   document.querySelector('meta[name="twitter:title"]') ? document.querySelector('meta[name="twitter:title"]').setAttribute("content", sTitle) : false;
      //   document.querySelector('meta[property="og:title"]') ? document.querySelector('meta[property="og:title"]').setAttribute("content", sTitle) : false;
      // }


      // set the header colour..
      setTimeout(() => {
        this.matchHeaderBG();
      }, 50);


      // update the app navigation
      this.EventBus.$emit("appCallback", {
        type: 'routeCallback',
        data: window.location.href.replace(/,\s*$/, "") + ((to.fullPath	== '/') ? '' : to.fullPath)
      });

      this.updateSectionClass(this.routeSection);

      // if (this.$store.getters.isBeta) { // && this.$store.getters.hasApp) {
      //   if (to.path == '/') {
      //     this.$router.push('/v3');
      //   }
      // }
    },
    showAccountModal() {
      if (!this.showAccountModal) {
        this.inboundCollected = false;
        this.$store.commit("storeArticleTime", {
          type: "inbound",
          time: false
        });
        this.$store.commit("storeArticleTime", {
          type: "outbound",
          time: false
        });
      }
    },
    loggedIn: {
      handler: function() {
        setTimeout(() => {
          // Setup intercom with logged in user..
          // if (this.loggedIn){
          //   this.setIntercom();
          // }

          // don't do any of the following "exclusion" stuff in the share-extension context.
          if (this.$route.query.scope == 'extension') {
            return;
          }

          // Present excluded publishers if onboarding step not complete
          if (!this.completedExcludedStep) {
            this.EventBus.$emit("handleExcludedSettings", {
              showExcluded: true
            });
          }

          // If logged in, push excluded publishers update to API if contained in local storage and clear onboarding local storage
          if (localStorage.getItem('ios_publisher_exclusion_flow_completed') && this.loggedIn) {
            localStorage.removeItem('ios_publisher_exclusion_flow_completed');
            localStorage.removeItem('excluded_publishers');
          }

        }, 500);
      },
      immediate: true
    },
    routeSection: function (sNew){
      this.updateSectionClass(sNew);
    }
  },
  computed: {
    account() {
      return this.$store.getters.getAccount;
    },
    isAppVersion() {
      return this.$store.getters.hasAppVersion;
    },
    minorAppVersion() {
      return this.$store.getters.minorAppVersion;
    },
    loggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    completedExcludedStep() {
      if (this.$store.getters.completedExcludedStep) {
        return this.$store.getters.completedExcludedStep;
      }
      return null;
    },
    showAccountModal() {
      return this.$store.getters.showAccountModal;
    },
    hasApp() {
      return this.$store.getters.hasApp;
    },
    hasNewApp() {
      let b1_6 = (this.isAppVersion >= 1.6);
      let b1_5_6 = ((this.isAppVersion >= 1.5) && (this.minorAppVersion >= 6));
      return this.hasApp && (b1_6 || b1_5_6);
    },
    hasChrome() {
      return this.$store.getters.hasChrome;
    },
    hasMini() {
      return this.$store.getters.hasMini;
    },
    hasTrends() {
      return this.$route?.meta?.display?.trends;
    },
    hasMobile() {
      return this.$store.getters.hasMobile;
    },
    // deprecated!! use hasMobile
    detectMobile() {
      return this.hasMobile;
    },
    getVersionNumber() {
      return this.$store.getters.appVersion;
    },
    routeSection(){
      return this.$route.path.substr(1).replace(/\/.*/,'');
    },
    threadShareOverlay() {
      let threadShareOverlay = this.$store.getters.getThreadShareOverlay || {};
      return threadShareOverlay.on ? threadShareOverlay : false;
    },
    isHTTP2xx() {
      return !(window['_os_http'] == 410);
    },
  }
};
</script>

<style lang="scss">
  // UN-SCOPED // GLOBAL SCOPE // UN-SCOPED
  @import "~styles/__2020_media.scss";
  @import "~styles/_variables";

  // Hide intercom on mobile.. 
  @media (max-width: 700px) {
    .intercom-launcher-frame, .intercom-lightweight-app {
      opacity: 0;
      display: none;
      visibility: hidden;
    }
  }

  html {
    @include palette-default;
    // saves a lot of javascript!
    scroll-behavior: smooth;

    // added for v5 (but appears to be harmless everywhere?)
    // breaks /open/status (and other places).. (not actually needed for v5?)
    // overflow: hidden;
  }

  // disable weird long-press stuff
  .mobile *:not(input):not(textarea) {
    -webkit-user-select: none; /* disable selection/Copy of UIWebView */
    -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
  }

  .outer-404 {
      height: 90vh;
      display: flex;
      align-items: center;
      justify-content: center;
      .inner-404 {
        margin: auto;
        text-align: center;

        p {
          margin: 1rem 0;
        }
      }
      .darkmode & {
        color: $white-primary;
      }


      .emoji {
        display: block;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 2rem;
        text-shadow: 3px 3px rgba($yellow-tone-3, 1.0);

        .darkmode & {
          text-shadow: 3px 3px rgba($yellow-tone-3, 0.5);
        }
      }
  }
</style>

<style lang="scss" scoped>
@import "~styles/_variables";
@import 'src/assets/scss/v5.scss';

  // SCOPED // LOCAL SCOPE // SCOPED
.main-content {
  
  // background: $white-primary;
  @include palette-default;

  // legacy min-height
  min-height: 100vh;

  padding-top: calc(env(safe-area-inset-top) + $header-height);
  padding-bottom: calc(env(safe-area-inset-bottom) + 5em); // add plenty of space for the tab-bar

  &.mini {
    padding-top: 0 !important;
  }

  // header-margin (legacy)
  // .is-web & {
  //   margin-top: 4rem;
  // }

  // v5 (and route-specified padding clearence)
  .is-web.route-clear & {
    // padding: 0 !important;
    // margin-top: 53px !important; // header height
  }

  // app
  .is-app.route-clear & {
    // padding: 0 !important;
    // margin-top: 0 !important; // header height
  }

  // body padding (also legacy)
  // padding: 1em 1em 2em 1em;
  // @media (max-width: 475px) {
  //   padding: 0.5em 0.5em 4em 0.5em;
  // }
}

.faux-footer {
  height: 100px;
  background: $white-secondary;
}

// Global -- penetrate to all components

::v-deep .beta-tag {
  height: 18px;
  font-family: $ff-head;
  color: $base-primary;
  font-size: 10px;
  background: $highlight-primary;
  padding: 3px 3px 0 3px;
  border-radius: 2px;
  margin-top: 2px;
  margin-left: 10px;	
  font-weight: bold;

  &.pro-tag {
    background: $highlight-pro;
  }		
}

.header-group {
  display: flex;
  flex-direction: column;

  .trending {
    top: 63px;
    position: fixed;
    width: 100%;
    z-index: 9999;

    @media (max-width: 699px) {
      top: 51px;
    }
  }
}

.test {
  margin-top: 10rem;
}
</style>
